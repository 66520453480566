import React from 'react';
import { Link } from 'gatsby';

import memberImage1 from '../../assets/image/hero/commercial.jpeg';
import memberImage2 from '../../assets/image/hero/webinar.jpeg';
import memberImage4 from '../../assets/image/hero/diy.jpeg';
import memberImage5 from '../../assets/image/hero/fashion.jpeg';
import memberImage3 from '../../assets/image/hero/museum.jpeg';

const TeamCard = ({ image, link, name, icons, position, ...rest }) => {
  return (
    <>
      {/* Single Team */}
      <div className="card-container">
        <div className="text-center mb-10 mb-lg-5">
          <div
            className="card-image-container full"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div className="content pl-10 pr-10 pb-0 pt-0">
            <h4 className="font-size-7 mb-1">{name}</h4>
            <p className="h-25 font-size-5 font-weight-normal mb-10">
              {position}
            </p>
            <div className="row text-center justify-content-center justify-content-evenly">
              {icons.map(({ name, iconclass, background, link }, index) => {
                return (
                  <Link
                    to={link}
                    title={name}
                    name={name}
                    key={index}
                    className="pt-0  mb-10"
                  >
                    <div
                      className={`${background} circle-60 text-white margin-auto-t-10 font-size-7`}
                    >
                      <i className={`fa ${iconclass}`} />
                      <i className="fa {}fa-chalkboard-teacher" />
                    </div>
                  </Link>
                );
              })}
            </div>
            <Link
              to={link}
              tabIndex="-1"
              className="w-100 h-55 btn btn-dodger-blue-2 rounded-5"
            >
              Explore
            </Link>
          </div>
        </div>
      </div>
      {/* Single Team */}
    </>
  );
};
const Team = ({ className, ...rest }) => {
  const TeamData = [
    {
      image: memberImage5,
      link: '/industries/ecommerce',
      name: 'E-commerce',
      position: 'Campaigns, Fashion, Influencers & Shoppable',
      icons: [
        {
          name: 'Campaigns',
          iconclass: 'fa-mail-bulk',
          background: 'bg-video',
          link: '/industries/ecommerce/campaigns',
        },
        {
          name: 'Fashion',
          iconclass: 'fa-tshirt',
          background: 'bg-image',
          link: '/industries/ecommerce/fashion',
        },
        {
          name: 'Influencers',
          iconclass: 'fa-chalkboard-teacher',
          background: 'bg-link',
          link: '/industries/ecommerce/influencers',
        },
        {
          name: 'Shoppable',
          iconclass: 'fa-tags',
          background: 'bg-form',
          link: '/industries/ecommerce/shoppable',
        },
      ],
      animation: {
        animationName: 'fade-up',
        delay: 300,
        duration: null,
      },
    },
    {
      image: memberImage4,
      link: '/industries/instructional',
      name: 'Instructional',
      position: 'Cooking, D.I.Y & How to',
      icons: [
        {
          name: 'Cooking',
          iconclass: 'fa-blender',
          background: 'bg-video',
          link: '/industries/instructional/cooking',
        },
        {
          name: 'D.I.Y',
          iconclass: 'fa-tools',
          background: 'bg-image',
          link: '/industries/instructional/diy',
        },
        {
          name: 'How to',
          iconclass: 'fa-chalkboard-teacher',
          background: 'bg-link',
          link: '/industries/instructional/how-to',
        },
      ],
      animation: {
        animationName: 'fade-up',
        delay: 300,
        duration: null,
      },
    },
    {
      image: memberImage2,
      link: '/industries/learning',
      name: 'Learning',
      position: 'Courses & Webinars',
      icons: [
        {
          name: 'Courses',
          iconclass: 'fa-graduation-cap',
          background: 'bg-link',
          link: '/industries/learning/courses',
        },
        {
          name: 'Webinars',
          iconclass: 'fa-chalkboard-teacher',
          background: 'bg-form',
          link: '/industries/learning/webinars',
        },
      ],
      animation: {
        animationName: 'fade-up',
        delay: 300,
        duration: null,
      },
    },
    {
      image: memberImage1,
      link: '/industries/real-estate',
      name: 'Real Estate',
      position: 'Commercial, Industrial & Residential',
      icons: [
        {
          name: 'Commercial',
          iconclass: 'fa-kaaba',
          background: 'bg-image',
          link: '/industries/real-estate/commercial',
        },
        {
          name: 'Industrial',
          iconclass: 'fa-warehouse',
          background: 'bg-link',
          link: '/industries/real-estate/industrial',
        },
        {
          name: 'Residential',
          iconclass: 'fa-home',
          background: 'bg-form',
          link: '/industries/real-estate/residential',
        },
      ],
      animation: {
        animationName: 'fade-up',
        delay: 300,
        duration: null,
      },
    },
    {
      image: memberImage3,
      link: '/industries/tours',
      name: 'Tours',
      position: 'Galleries, Attractions & Museums',
      icons: [
        {
          name: 'Galleries',
          iconclass: 'fa-palette',
          background: 'bg-form',
          link: '/industries/tours/galleries',
        },
        {
          name: 'Attractions',
          iconclass: 'fa-map-marked-alt',
          background: 'bg-video',
          link: '/industries/tours/attractions',
        },
        {
          name: 'Museums',
          iconclass: 'fa-hotel',
          background: 'bg-link',
          link: '/industries/tours/museums',
        },
      ],
      animation: {
        animationName: 'fade-up',
        delay: 300,
        duration: null,
      },
    },
  ];
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* section title */}
        <div className="row">
          <div className="col-12">
            <h3 className="font-size-9 text-digma-dark mb-10 text-center">
              Pick Your Industry
            </h3>
            <div className="testimonial-one flex wrap">
              {TeamData.map(({ image, name, link, icons, position }, index) => {
                console.log(icons);
                return (
                  <div key={index} className="col-4">
                    <TeamCard
                      image={image}
                      link={link}
                      name={name}
                      icons={icons}
                      position={position}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
