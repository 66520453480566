import React from "react";
import {Link} from "gatsby";
import Wave from "../sections/common/Wave";
import Hero from "../sections/industries/Hero";

import PageWrapper from "../components/PageWrapper";
import Team from "../sections/industries/Team";
import Pricing from "../sections/home4/Pricing";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import WaveReverse from "../sections/common/WaveReverse";
import Commit from "../sections/common/Commit";
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/default.jpg'

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>        
        <SEO
          title="Interactive Video Platform for all Industries"
          description="Suitable for all industries, Digma’s highly advanced SAAS platform you can now transform all your video content into a cutting-edge interactive experience."
          image={metaImg}
        />          
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true">/ </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
              </li>
              </ol>
           </nav>
        </div>           
        <Hero />
        <Wave color="#F7F9FC" /> 
        <Team className="grey pt-13 pt-lg-12 pb-15 pb-lg-12" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Pricing />
        <div className='d-none d-md-block text-center pb-10 pt-5'>
        <AniLink className="font-size-7" paintDrip hex="#2196f3" to="/pricing">
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
